import * as React from 'react';
import styled from 'styled-components';

export default function IndexPage() {
  return (
    <Main>
      <Heading>tomk productions</Heading>
    </Main>
  );
}

const Main = styled.main`
  align-items: center;
  background: #9ebb9f;
  color: #788476;
  display: flex;
  font-family: -apple-system, Roboto, sans-serif, serif;
  font-weight: normal;
  height: 100vh;
  justify-content: center;
`;

const Heading = styled.h1`
  margin: 0;
`;
